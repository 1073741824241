import React from 'react';
import { Box, Avatar, Typography } from '@mui/material';
import { useLoaderData } from 'react-router-dom';
import CardButton from '../components/UI/CardButton';
import Breadcrumb from '../components/Breadcrumb/Breadcrumb';
import { fetchMenuItems } from '../utils/cms';
import { userInfo } from '../utils/cms';
function Profile() {
    const loaderData = useLoaderData();
    const { name, job, photo,age,gender,civil_state_data } = loaderData.profile;
    const { cardMenuItems } = loaderData;

const breadcrumbLabels = {
    home: 'Home',
    profile: 'Profile',
}







    // Breadcrumb data
    const breadcrumbData = {
        data: [
            { label: breadcrumbLabels.home, url: "/" },
            { label: breadcrumbLabels.profile, url: "/profile" },
        ],
    };

    return (
        <>
            {/* Breadcrumb */}
            <Breadcrumb data={breadcrumbData.data} />

            {/* Profile Header */}
            <Box style={{ WebkitBoxShadow: '0px 0px 12px 0px #bdcdd1' }} className="p-8 text-black flex flex-col items-center">
                {/* Profile Picture and Rating */}
                <Box className="relative flex flex-col items-center">
                    <Avatar
                        src={`${process.env.REACT_APP_API_URL}${photo}`}
                        alt="Profile Picture"
                        sx={{ width: 100, height: 100 }}
                    />
                    <Box className="absolute bottom-0 right-0 transform translate-x-1/4 translate-y-1/4 bg-pink-500 text-white py-1 px-2 rounded-full">
                        <Typography variant="caption">{age}</Typography>
                    </Box>
                </Box>

                {/* Name and Info */}
                <Typography variant="h6" className="mt-4 font-bold text-black">
                    {name}
                </Typography>
                <Typography variant="body2" className="mt-2 text-gray-600">
                   <span style={{color:civil_state_data.color}}>{civil_state_data.name}</span>  {job && '| ' + job}
                </Typography>
                <Typography variant="body2" className="mt-2 flex gap-2">
                   <span className={gender.icon}></span>
                   <span style={{color:gender.color}}>{gender.name}</span>
                </Typography>
            </Box>

            {/* Cards Grid */}
            <Box id="cards-grid" className="grid grid-cols-2 gap-4 justify-center p-16 w-full sm:w-2/3 xl:w-1/2 mx-auto">
                {cardMenuItems && cardMenuItems.map((item, index) => (
                    <CardButton
                        key={index}
                        link={item.destination}
                        iconClass={item.icon}
                        title={item.name}
                    />
                ))}
            </Box>
        </>
    );
}

export default Profile;


export const loader = async () => {


    try {
      const profileData = await userInfo(); // Fetch profile details
      const menuData = await fetchMenuItems('card-profile-menu'); // Fetch menu items
      //console.log(menuData);
      return {
        profile: profileData || {},
        cardMenuItems: menuData?.items["card-profile-menu"] || [],
      };
    } catch (error) {
      console.error('Error loading profile data:', error);
      return {
        profile: {},
        cardMenuItems: [],
      };
    }
  };