import React, { forwardRef, useState, useEffect } from 'react';
import { TextField, FormControl, FormControlLabel, InputLabel, OutlinedInput, InputAdornment, Select, MenuItem } from '@mui/material';
import { Checkbox } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Link, json } from 'react-router-dom';
import LinkMui from '@mui/material/Link';
import { Typography } from '@mui/material';
import P4lIcon from './P4lIcon';
import { IconButton } from '@mui/material';
import UploadImage from './UploadImage';
import FormHelperText from '@mui/material/FormHelperText';
import { fetchSelectItems } from '../../utils/cms';
import Autocomplete from '@mui/material/Autocomplete';
import { Rating } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useParams } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import DrawerRight from './DrawerRight';
import dayjs from 'dayjs';

const Input = forwardRef(({ errorMessage, type, label, name, placeholder, selectObject, selectLabel, to, text, color,size, ...rest }, ref) => {
  const params = useParams();
  const modelid = params?.id;

  // Hooks
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [selectItems, setSelectItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState('');
  const [selectedDate, setSelectedDate] = useState(dayjs(rest.value).isValid() ? dayjs(rest.value) : null);
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(false);


    // Adjust viewport height dynamically


    // Ensure input scrolls into view when focused
    const handleFocus = (e) => {
      e.target.scrollIntoView({ behavior: "smooth", block: "center" });
    };
  const handleDateChange = (newValue) => {
    if (dayjs(newValue).isValid()) {
      setSelectedDate(newValue);
    } else {
      //console.log('Invalid date:', newValue);
    }
  };



  useEffect(() => {
    if ((type === 'select' || type === 'select-autocomplete' || type === 'select-datatable') && selectObject) {
      async function fetchItems() {
        try {
          const data = await fetchSelectItems(selectObject, name, modelid);
          if (data) {
            //console.log(data);
            //console.log(selectObject)
            //console.log(selectObject.search)
            setSelectItems(data);
            setSelectedItem(data[0]);
          }
        } catch (error) {
          throw json({ message: error.text }, { status: error.status });
        }
      }
      fetchItems();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (type === 'select-autocomplete' && selectItems.id) {
      const matchingItem = selectItems.find((item) => item.id === rest.value.id);
      if (matchingItem) {
        setSelectedItem(matchingItem);
      }
    }
  }, [rest.value, selectItems, type]);

  useEffect(() => {
    if (type === 'select-autocomplete' && searchTerm.length >= 3) {
      const delayDebounceFn = setTimeout(() => {
        fetchData(searchTerm);
      }, 500);

      return () => clearTimeout(delayDebounceFn);
    }
    // eslint-disable-next-line
  }, [searchTerm, type]);

  const fetchData = async (searchTerm) => {
    if (searchTerm.length < 3) return;

    setLoading(true);
    try {
      const url = `webapp/updater`;
      const token = localStorage.getItem('token');
      const headers = token
        ? { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
        : { 'Content-Type': 'application/json' };

      const response = await fetch(`${process.env.REACT_APP_API_URL}${url}`, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({
          action: 'select_search',
          search_term: searchTerm,
          fields: selectObject.search.filter_search,
          app_name: selectObject.search.app_name,
          model_name: selectObject.search.model_name,
          model_key_id: selectObject.search.model_key_id,
          field_key: selectObject.field_key,
        }),
      });



      const result = await response.json();
      const filteredData = result.data.filter((item) => item !== null);
      setSelectItems(filteredData);
    } catch (error) {
      console.error('Failed to fetch data:', error);
    } finally {
      setLoading(false);
    }
  };



  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  if (type === 'select') {

    //console.log(rest.value)

      //console.log(selectItems);

    return (
      <FormControl size={size ? size : 'medium'} fullWidth {...rest}>
      {size !== 'small' && (
        <InputLabel
          sx={{
            color: color,
          }}
          shrink={true}
          id={`custom-select-label-${name}`}
        >
          {label}
        </InputLabel>
      )}
      <Select
       renderValue={(selected) => {
        const selectedItem = selectItems.find((item) => item.id === selected);
        if (!selectedItem) return null;

        return size === 'small' ? (
          selectedItem.icon && (
            <P4lIcon
              size="medium"
              iconUrl={`${process.env.REACT_APP_API_URL}${selectedItem.icon.slice(1)}`}
            />
          )
        ) : (
          <div className="flex items-center">
            {selectedItem.icon && (
              <P4lIcon
                size="medium"
                iconUrl={`${process.env.REACT_APP_API_URL}${selectedItem.icon.slice(1)}`}
              />
            )}
            {selectedItem.value}
          </div>
        );
      }}
        sx={{
          color: color,
          '& .MuiOutlinedInput-notchedOutline': {
            borderRadius: size === 'small' ? '5px' : '10px',
            borderColor: size === 'small' ? 'transparent' : color,
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: size === 'small' ? 'transparent' : color,
          },
          '& .MuiSvgIcon-root': {
            color: color,
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: size === 'small' ? 'transparent' : color,
          },
          '& .MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input': {
            paddingRight: size === 'small' ? '20px' : 'auto',
          },
        }}
        displayEmpty
        error={Boolean(errorMessage)}
        label={label}
        name={name}
        inputRef={ref}
        labelId={`custom-select-label-${name}`}
        id={`custom-select-${name}`}
        {...rest}
      >
        {selectItems &&
          selectItems.map((item) => (
            <MenuItem key={item.id} value={item.id} className="flex">
              {item.icon && (
                <P4lIcon size="medium" iconUrl={`${process.env.REACT_APP_API_URL}${item.icon.slice(1)}`} />
              )}
              {item.value}
            </MenuItem>
          ))}
      </Select>
      {errorMessage && (
        <FormHelperText sx={{ color: 'error.main' }} id="validation-async-first-name">
          {errorMessage}
        </FormHelperText>
      )}
    </FormControl>

    );
  }
  else if (type === 'select-autocomplete') {
    const handleAutocompleteChange = (event, newValue) => {
      setSelectedItem(newValue); // Update selected item
      rest.onChange(newValue?.id || ''); // Trigger onChange callback, passing the ID of the selected item
    };

    return (
      <FormControl fullWidth {...rest}>
        <InputLabel shrink={true} id={`custom-select-label-${name}`}>
          {label ? label : selectItems[0]?.label || ''}
        </InputLabel>
        <Autocomplete
          inputRef={ref}
          value={selectedItem || ''}
          onChange={handleAutocompleteChange} // Handles selection
          options={selectItems}
          getOptionLabel={(option) => option.value || ''} // Display the value as the label in the autocomplete list
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              sx={{
                '& .MuiOutlinedInput-root': {
                  borderRadius: '10px', // Set the border-radius
                  color: color, // Set text color
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: color, // Change border color when focused
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: color, // Change border color
                  },
                },
                '& .MuiInputLabel-root': { // Label styling
                  color: color, // Change label color
                },
                '& .MuiInputLabel-root.Mui-focused': { // Label color when input is focused
                  color: color,
                },
                '& .MuiPlaceholder-root': { // Placeholder styling
                  color: color, // Change placeholder color
                }
              }}
              name={`${name}-select`} // Use a specific name for the field
              value={searchTerm} // Bind search term for searching
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => setSearchTerm(e.target.value)} // Capture search term
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loading ? <CircularProgress size={20} /> : null} {/* Show loading indicator */}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
        />
        <input type="hidden" name={name} value={selectedItem ? selectedItem.id : ''} /> {/* Hidden input for form submission */}
      </FormControl>
    );
  }

  else if (type==='select-datatable'){

    const handleOpenDrawer = () => setIsDrawerOpen(true);

    const handleCloseDrawer = () => setIsDrawerOpen(false);

    const handleItemSelect = (value) => {
      // Assuming you want to update `inputValue` or a similar state to display the selected value
      setSelectedItem(value)
      //console.log(selectedItem)
      // If you need to do something with the selected item's ID or value,
    };
//console.log(selectItems)
    return(
      <FormControl fullWidth {...rest}>
      <TextField
        InputLabelProps={{ shrink: true }}
        error={Boolean(errorMessage)}
        variant="outlined"
        inputRef={ref}
        fullWidth
        label={label}

        placeholder={placeholder}
        value={(selectedItem && selectedItem.value) || ''} // Display the selected item's value
        InputProps={{
          readOnly: true, // Make TextField read-only if it should not be editable
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={handleOpenDrawer} aria-label="search">
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
        // Spread the rest of the props if necessary
      />
      <input type="hidden" name={name} value={selectedItem ? selectedItem.id : ''} />
      {isDrawerOpen && (
        <DrawerRight
          open={isDrawerOpen}
          onConfirm={handleItemSelect}
          onClose={handleCloseDrawer}

          selectBoxData={ selectItems} // Assuming this prop controls the data displayed for selection
          onItemSelect={handleItemSelect} // Prop to handle selection
           // Example title, adjust as needed
          // Add any additional props required by DrawerRight for its other functionalities
        />
      )}
    </FormControl>
    )
  }

  else if (type === 'password') {
    return (
      <FormControl fullWidth {...rest}>
        <InputLabel
          shrink={true}
          htmlFor={`custom-input-${name}`}
          sx={{
            color: `${color} !important`,
            '&.Mui-focused': {
              color: `${color} !important`, // Ensure the color doesn't change on focus
            },
          }}
        >
          {label}
        </InputLabel>
        <OutlinedInput
          sx={{
            borderRadius: '10px',
            '& fieldset': {
              borderColor: color, // Set the border color dynamically
              borderRadius: '10px',
            },
            '&:hover fieldset': {
              borderColor: color, // Maintain color on hover
            },
            '&.Mui-focused fieldset': {
              borderColor: `${color} !important`, // Border color when the field is focused
            },
            color: color, // Text color
          }}
          notched
          label={label}
          variant="outlined"
          name={name}
          error={Boolean(errorMessage)}
          inputRef={ref}
          id={`custom-input-${name}`}
          type={showPassword ? 'text' : 'password'}
          placeholder={placeholder}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleTogglePasswordVisibility}
                edge="end"
                sx={{ color: color }} // Icon button color
              >
                <P4lIcon className={`text-${color}`} icon={showPassword ? "p4l-eye-blocked" : "p4l-eye"} />
              </IconButton>
            </InputAdornment>
          }
          autoComplete="new-password" // Disable browser autofill
        />
        {errorMessage && (
          <FormHelperText sx={{ color: 'error.main' }} id={`validation-${name}`}>
            {errorMessage}
          </FormHelperText>
        )}
      </FormControl>
    );
  }
  else if (type === 'date') {

//console.log(type)
//console.log(rest.value)
//console.log(name)
    return (
      <FormControl fullWidth {...rest}>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt">

        <DatePicker  label={label}  format="YYYY-MM-DD"   slotProps={{
        textField: {
          // Pass InputLabelProps via slotProps to the TextField
          sx: { '& .MuiOutlinedInput-root': { borderRadius: '10px' } },
          InputLabelProps: { shrink: true },
          name: `${name}`,
          error: Boolean(errorMessage)

        },
      }} inputRef={ref} placeholder={placeholder} id={`custom-date-input-${name}`}  {...rest} name={name} value={selectedDate} onChange={handleDateChange} />

        </LocalizationProvider>
        {errorMessage && (
                  <FormHelperText sx={{ color: 'error.main' }} id='validation-async-first-name'>
                    {errorMessage}
                  </FormHelperText>
                )}
      </FormControl>
    );
  }
  else if (type === 'checkbox') {
    const handleCheckboxChange = (event) => {
      // Convert boolean to string "True" or "False"
      const value = event.target.checked ? 'True' : 'False';
      // Trigger the change handler if provided
      if (rest.onChange) {
        rest.onChange(value);
      }
    };
    //console.log(label)
    return (
      <>
      <input type="hidden" name={name}  value={rest.value === 'True' ? 'True' : 'False'} />
      <FormControlLabel
        className='flex justify-center'
        control={<Checkbox   defaultChecked={rest.value ==='True'}  sx={{
          color: color, // color when not checked
          '&.Mui-checked': {
            color: color, // color when checked
          },
          '& .MuiSvgIcon-root': { // Ensures the icon itself also inherits the dynamic color
            fill: color, // Adjust SVG icon color
          }
        }} inputRef={ref}  />}
        onChange={handleCheckboxChange}
        value={rest.value === 'True' ? 'True' : 'False'}
        label={label}

        sx={{
          '& .MuiTypography-root': { // Target the label specifically if needed
            color: color, // Dynamic color for label text
          }
        }}
      />
      </>
    );
  }
  else if (type === 'upload-image') {
    return (

     <UploadImage ref={ref} name={name} label={label} {...rest}   />
    );
  }

else if (type === 'link') {
  //console.log(label)
  return (
    <Link to={to} {...rest} className='h-full flex justify-center' >

       <Typography  variant='body1' className='text-primary-accent flex items-center h-full'>
      <LinkMui  sx={{ color: 'primary.accent', textDecoration: 'underline' }} >{label}</LinkMui>
      </Typography>
    </Link>
  );
}
else if (type === 'rating') {
  //console.log(label)
  return (
    <>
    <Typography component="legend">{label}</Typography>
    <Rating name="read-only" value={rest.value} readOnly />
    </>
  );
}
else if (type === 'text-area') {
  return (
    <FormControl fullWidth {...rest}>
    <TextField           multiline
          rows={4}
     sx={{
        '& .MuiOutlinedInput-root': {
          borderRadius: '10px', // Set the border-radius
          color: color, // Set text color
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: color, // Change border color when focused
          },
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: color, // Change border color
          },
        },
        '& .MuiInputLabel-root': { // Label styling
          color: color, // Change label color
        },
        '& .MuiInputLabel-root.Mui-focused': { // Label color when input is focused
          color: color,
        },
        '& .MuiPlaceholder-root': { // Placeholder styling
          color: color, // Change placeholder color
        }
      }}  InputLabelProps={{
          shrink: true,
        }} error={Boolean(errorMessage)}  variant='outlined' inputRef={ref} fullWidth {...rest} label={label} name={name} placeholder={placeholder} type={type} />
    {errorMessage && (
                <FormHelperText sx={{ color: 'error.main' }} id='validation-async-first-name'>
                  {errorMessage}
                </FormHelperText>
              )}
    </FormControl>
  );
}

  else {
    return (
      <FormControl fullWidth {...rest}>
      <TextField  onFocus={handleFocus}    sx={{
          '& .MuiOutlinedInput-root': {
            borderRadius: '10px', // Set the border-radius
            color: color, // Set text color
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: color, // Change border color when focused
            },
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: color, // Change border color
            },
          },
          '& .MuiInputLabel-root': { // Label styling
            color: color, // Change label color
          },
          '& .MuiInputLabel-root.Mui-focused': { // Label color when input is focused
            color: color,
          },
          '& .MuiPlaceholder-root': { // Placeholder styling
            color: color, // Change placeholder color
          }
        }}  InputLabelProps={{
            shrink: true,
          }} error={Boolean(errorMessage)}  variant='outlined' inputRef={ref} fullWidth {...rest} label={label} name={name} placeholder={placeholder} type={type} />
      {errorMessage && (
                  <FormHelperText sx={{ color: 'error.main' }} id='validation-async-first-name'>
                    {errorMessage}
                  </FormHelperText>
                )}
      </FormControl>
    );
  }

});

export default Input;
