import PlatFormLogo from "../UI/PlatFormLogo";
import Box from "@mui/material/Box";
import {  CardContent } from "@mui/material";
import SocialAuth from "./SocialAuth";
import React,{useEffect} from "react";




const AuthForm = ({ children , social }) => {

  const removePreloader = () => {
    const preloader = document.getElementById('preloader');
    if (preloader) {
      preloader.classList.add('hidden'); // Add the `hidden` class to fade out

    }
  };

  useEffect(() => {
   removePreloader();

      }, []);


    return (

        <Box
        className="flex flex-col items-center justify-center min-h-screen gap-8 p-6"
        style={{
          backgroundImage: `url("${process.env.PUBLIC_URL}/static/images/background-auth__.png")`,
          backgroundSize: 'cover',
          backgroundPosition:'center center',
          backgroundRepeat: 'no-repeat',

          zIndex:-1,

        }}
        >

<PlatFormLogo type='entrance' className="max-h-16 mb-6"/>

<Box   className=" p-0 sm:p-6 w-full  sm:w-1/2 lg:w-1/3">


<CardContent className="flex flex-col gap-8 p-0 w-full justify-center items-center">










        {children}

       <SocialAuth social={social}/>
       </CardContent>
       </Box>

        </Box>

    );
  };

  export default AuthForm;