import React, { useState, useEffect } from 'react';
import { Box, CircularProgress } from '@mui/material';
import CardButton from '../components/UI/CardButton';
import Breadcrumb from '../components/Breadcrumb/Breadcrumb';
import { fetchMenuItems } from '../utils/cms';
import { useLoaderData } from 'react-router-dom';
function Account() {

    const [breadcrumbLabels, setBreadcrumbLabels] = useState({
        home: 'Home',
        account: 'Account',
    });
    const [isLoading, setIsLoading] = useState(true);
    const loaderData = useLoaderData();
    const { cardMenuItems } = loaderData;


    // Breadcrumb data
    const breadcrumbData = {
        data: [
            { label: breadcrumbLabels.home, url: "/" },
            { label: breadcrumbLabels.account, url: "/account" },
        ],
    };

    // Show a loading spinner while fetching data


    return (
        <Box>
            {/* Breadcrumb */}
            <Breadcrumb data={breadcrumbData.data} />

            {/* Cards Grid */}
            <Box id="cards-grid" className="grid grid-cols-2 gap-4 justify-center p-16 w-full sm:w-2/3 xl:w-1/2 mx-auto">
                {cardMenuItems && cardMenuItems.map((item, index) => (
                    <CardButton
                        key={index}
                        link={item.destination}
                        iconClass={item.icon}
                        title={item.name}
                    />
                ))}
            </Box>
        </Box>
    );
}

export default Account;
export const loader = async () => {


    try {

      const menuData = await fetchMenuItems('card-account-menu'); // Fetch menu items

      return {

        cardMenuItems: menuData?.items["card-account-menu"] || [],
      };
    } catch (error) {
      console.error('Error loading account data:', error);
      return {

        cardMenuItems: [],
      };
    }
  };