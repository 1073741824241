import { motion } from "framer-motion";
import React, { useState } from "react";
import { useInView } from "react-intersection-observer";
import { Link } from "react-router-dom";
function Accordion({accordionData}) {
    const [ref, inView] = useInView({ triggerOnce: true, threshold: 0.2 });
    const [openSection, setOpenSection] = useState(accordionData.seguros.length> 0 ? "seguros" : "creditos");
    const [openSubItem, setOpenSubItem] = useState(null);


/*

//console.log(accordionData);
    /*
    const accordionData = {
        "seguros": [
            { id: "1", title: "Pessoal e Familiar", icon: "p4l-family", description: "Tem 5 Activos", premium: "5000€", color: "text-green-400" },
            { id: "2", title: "Saúde", icon: "p4l-health", description: "Tem 5 Activos", premium: "5000€", color: "text-pink-400" },
            { id: "3", title: "Não Vida", icon: "p4l-lifebuoy", description: "Tem 3 Activos", premium: "5000€", color: "text-orange-400" }
        ],
        "creditos": [
            { id: "4", title: "Crédito Pessoal", icon: "p4l-golden-bars", description: "Tem 3 Activos", premium: "3000€", color: "text-blue-400" },
            { id: "5", title: "Crédito Habitação", icon: "p4l-home", description: "Tem 2 Activos", premium: "5000€", color: "text-green-400" },
            { id: "6", title: "Crédito Consolidado", icon: "p4l-golden-bars", description: "Tem 4 Activos", premium: "7000€", color: "text-orange-400" }
        ]
    };*/



    const toggleSection = (section) => setOpenSection(section);
    const toggleSubItem = (subItem) => setOpenSubItem(openSubItem === subItem ? null : subItem);

    const containerVariants = {
        hidden: { opacity: 0 },
        visible: { opacity: 1, transition: { staggerChildren: 0.1, delayChildren: 0.2 } },
    };

    const itemVariants = {
        hidden: { opacity: 0, y: 50 },
        visible: { opacity: 1, y: 0, transition: { type: "spring", stiffness: 100 } },
    };
    const subItemVariants = {
        closed: { opacity: 0, height: 0, transition: { duration: 0.2 } },
        open: { opacity: 1, height: "auto", transition: { duration: 0.2 } }
    };
    return (
        <React.Fragment>
            <div className="w-full">
                <div className="flex gap-1 justify-center sm:justify-center bg-primary-light text-xl whitespace-nowrap w-full">
                {Object.keys(accordionData).map((sectionKey) =>
    accordionData[sectionKey] && (
        <button
            key={sectionKey}
            onClick={() => toggleSection(sectionKey)}
            className={`border border-b-0  inline-block min-w-32 sm:min-w-64 py-3 px-2 transition-all duration-200 ease-out ${
                openSection === sectionKey
                    ? 'text-black bg-white'
                    : 'text-gray-400 hover:bg-white hover:text-black'
            } font-bold`}
        >
            {sectionKey.charAt(0).toUpperCase() + sectionKey.slice(1)}
        </button>
    )
)}
                </div>
            </div>
            <div ref={ref} className="w-full p-6 bg-white">
                {openSection && (
                    <div id={openSection} className=" w-full">
                        <motion.div initial="hidden" animate={inView ? "visible" : "hidden"} variants={containerVariants} className="flex flex-col gap-6 w-full">

                        {accordionData[openSection].length > 0 ? (
                accordionData[openSection].map((item) => (
                                <motion.div key={item.id} variants={itemVariants} className="flex flex-col items-center gap-2 w-full">
                                    <div onClick={() => toggleSubItem(item.id)}
                                         className={`flex items-center  justify-between w-full sm:w-2/3 xl:w-1/2 px-6 py-2 sm:py-4 gap-3 font-medium text-gray-500 border border-b-0 border-gray-200 rounded-lg cursor-pointer `} style={{ WebkitBoxShadow: '0px 0px 12px 0px #bdcdd1' }}>
                                        <div className="flex justify-between items-center w-full">
                                            <div className="flex gap-4 items-center">
                                                <span style={{color:item.color}} className={`${item.icon} text-4xl `}></span>
                                                <span>{item.title}</span>
                                            </div>
                                            <p  style={{color:item.color}} >{item.description}</p>
                                        </div>
                                        <svg data-accordion-icon className="w-3 h-3 rotate-180 shrink-0" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5 5 1 1 5"/>
                                        </svg>
                                    </div>
                                    {openSubItem === item.id && item.cards && (
                                         <motion.div
                                         variants={subItemVariants}
                                         initial="closed"
                                         animate={openSubItem === item.id ? "open" : "closed"} className={`flex  border p-6 border-gray-200 flex-wrap gap-4 justify-center w-full rounded-lg sm:w-2/3 xl:w-1/2`} aria-labelledby={`accordion-collapse-heading-${item.id}`}>
                                             {item.cards.map((card, index) => (
                                            <Link  key={index} to={card.link}>
                                            <div style={{ WebkitBoxShadow: '0px 0px 12px 0px #bdcdd1' }} className="p-2  flex rounded-lg md:flex-row md:max-w-xl">

                                                <div className="flex flex-col items-center gap-8 w-full p-4 ">
                                                    <div className="w-32 min-h-10 max-h-10 flex items-start justify-center overflow-hidden ">
                                               {card.fp.logo ?  <img
                              src={`${process.env.REACT_APP_API_URL}${card.fp.logo.slice(1)}`}
                              alt={card.fp.name}
                              className="max-w-full max-h-full"
                            /> : <span className=" w-full">{card.fp.name}</span> }
                            </div>
                                                    <div className="flex min-w-[15rem] max-w-[15rem] w-full justify-around">
                                                        <div className="flex flex-col gap-2 items-center px-8">
                                                            <div className="flex gap-12">
                                                                <div>
                                                            <p>Prémio</p>
                                                            <p className="font-extrabold text-center">{card.total}</p>
                                                            </div>
                                                            <div>
                                                            <p className="whitespace-nowrap">Custo Total</p>
                                                            <p className="font-extrabold text-center">{card.cost}</p>
                                                            </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            </Link>
                                               ))}
                                        </motion.div>
                                    )}
                                </motion.div>
                                 ))
                            ):(
                                <div className="text-center text-gray-500">
                                  Ainda não temos informação sobre {openSection}.
                                </div>
                              ) }
                        </motion.div>
                    </div>
                )}
            </div>
        </React.Fragment>
    );
}

export default Accordion;
