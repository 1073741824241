import { useEffect ,useContext} from 'react';
import { useNavigate } from 'react-router-dom';
import { getCSRFToken } from '../utils/auth';
import AuthContext from '../store/context/auth-context__';
function ResendEmailVerify() {
  const navigate = useNavigate();
  const authCtx = useContext(AuthContext);
  useEffect(() => {
    const resendVerificationEmail = async () => {
      const email = localStorage.getItem('email') || authCtx.userData.email; // Retrieve email from localStorage
      //console.log(email);
      if (!email) {
        console.error('No email found in localStorage');
        navigate('/notification', {
          state: {
            messages: {
              title: 'email-not-found',
              success: 'email-not-found-message',
              instructions: 'please-login-to-resend-email',
            },
            buttons: [{
              text: 'login',
              link: '/login',
            }],
          },
        });
        return;
      }

      try {
        const csrfToken = getCSRFToken();
        const response = await fetch(`${process.env.REACT_APP_API_URL}_allauth/browser/v1/account/email`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': csrfToken,
          },
          credentials: 'include',
          body: JSON.stringify({ email }) // Send the email in the request body
        });

        if (response.ok) {
          // Email was successfully resent
          navigate('/notification', {
            state: {
              messages: {
                title: 'email-resend-success',
                success: 'email-resend-success-message',
                instructions: 'email-resend-check-inbox',
              },
              buttons: [{
                text: 'continue',
                link: '/',
              }],
            },
          });
        } else {
          // Handle failure to resend
          navigate('/notification', {
            state: {
              messages: {
                title: 'email-resend-failed',
                success: 'email-resend-failure-message',
                instructions: 'email-resend-contact-support',
              },
              buttons: [{
                text: 'retry',
                link: '/resend-email',
              }, {
                text: 'home',
                link: '/',
              }],
            },
          });
        }
      } catch (error) {
        console.error('Failed to resend verification email:', error);
        // Handle network or other errors
        navigate('/notification', {
          state: {
            messages: {
              title: 'email-resend-failed',
              success: 'email-resend-failure-message',
              instructions: 'email-resend-contact-support',
            },
            buttons: [{
              text: 'retry',
              link: '/resend-email',
            }, {
              text: 'home',
              link: '/',
            }],
          },
        });
      }
    };

    resendVerificationEmail();
    // eslint-disable-next-line
  }, [navigate]);

  return null;
}

export default ResendEmailVerify;
