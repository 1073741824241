import React, { useState, useEffect } from 'react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';

import Root from './pages/Root';
import Home from './pages/Home';
import Login from './pages/Login';
import ForgotPassword from './pages/ForgotPassword';
import ResendEmailVerify from './pages/ResendEmailVerify';
import ResetPassword from './pages/ResetPassword';
import NotificationPage from './pages/NotificationPage';
import Authorizations from './pages/Authorizations';
import ChangePassword from './pages/ChangePassword';
import Register from './pages/Register';
import { ThemeProvider } from '@mui/material/styles';
import { ActionDataProvider } from './store/context/action-context';
import VerifyEmail from './pages/VerifyEmail';
import { AuthContextProvider } from './store/context/auth-context__';
import theme from './theme';
import Agenda from './pages/Agenda';
import Dashboard from './pages/Dashboard';
import Account from './pages/Account';
import { action as registerAction } from './pages/Register';
import { action as loginAction } from './pages/Login';
import {action as forgotPasswordAction} from './pages/ForgotPassword';
import {action as resetPasswordAction} from './pages/ResetPassword';
import Profile from './pages/Profile';
import { action as formAction } from './components/UI/DynamicForm/FormLayout';
import { SnackbarProvider } from 'notistack';
import { fetchDataObject } from './utils/cms';
import { fetchForm } from './utils/cms';
import DynamicLayoutRoot from './components/UI/DynamicLayoutRoot';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import Financials from './pages/Financials';
import ErrorPage from './pages/ErrorPage';
import ErrorBoundary from './utils/ErrorBoundary';
import { SnackBarContextProvider } from './store/context/snackbar-context';
import LeadGeneric from './pages/LeadGeneric';
import ContentPage from './pages/ContentPage';
import { loader as homeLoader } from './pages/Home';
import { loader as contentLoader } from './pages/ContentPage';

import DynamicComponent from './pages/DynamicComponent';
import { TranslationProvider } from './store/context/translations-context';
import Integrations from './pages/Integrations';
import { loader as rootLoader } from './pages/Root';
import { loader as profileLoader } from './pages/Profile';
import { loader as accountLoader } from './pages/Account';
import { loader as financialsLoader } from './pages/Financials';
import { fetchCalendar } from './utils/cms';

const App = () => {
  const [routesData, setRoutesData] = useState(null);
let isLoading = true;
  const token = localStorage.getItem('token');

  useEffect(() => {
localStorage.setItem("apiUrl",process.env.REACT_APP_API_URL);

  }, []);


  useEffect(() => {

    const fetchRoutesData = async () => {

      try {





        const response = await fetch(process.env.REACT_APP_API_URL+ 'webapp/routes', {
          method: 'GET',

        });
        const data = await response.json();
        //console.log(data);
        setRoutesData(data);

      } catch (error) {
        console.error('Error fetching routes data:', error);
      }
    };

    fetchRoutesData();
  }, [token]);

  if (!routesData) {
    return <div className='bg-white w-full flex justify-center  items-center min-h-screen'><img className='max-w-xs' src={`${process.env.PUBLIC_URL}/static/images/loading.gif`} alt="Logo"/></div>
  }

  /*const dynamicRoutes = routesData
  ? Object.keys(routesData).flatMap(key => {
      const { tabs, formData } = routesData[key];
      //console.log(formData)
      //console.log(tabs)
      return tabs.map(tab => ({
        path: `${key}/${tab.value}`,
        element: <DynamicLayoutRoot tabSelected={tab.value} tabs={tabs} data={formData && formData[tab.value]} />,
        action: formAction,
        loader: () =>
        formLoaderData(tab.loader),
      }));
    })
  : [];*/

  //console.log(routesData)
  const dynamicRoutes = routesData ? (() => {
    const addedPaths = new Set();
    const routes = [];
    //console.log(routesData)
    Object.keys(routesData).forEach(key => {
      //console.log(routesData);
      const menuItem = key;
      //console.log(menuItem);
      let component;
      let hasLoader= false;
      let parent;
      // Map specific keys to their respective components
      switch (menuItem) {

        case 'dashboard':
          component = <Dashboard/>;
          hasLoader=false;
          break;
        case 'info':
        case 'security':
          parent="account";
          component = <DynamicLayoutRoot parentItem={parent} context={key}/>;
          //console.log(component)
          hasLoader=true;
          break;
          case 'edit-profile':
            parent="profile"
            component = <DynamicLayoutRoot parentItem={parent} context={key}/>;
            //console.log(component)
            hasLoader=true;
            parent="profile"
            break;
        // Add other specific cases as needed
        default:
          //console.log(`Component for ${menuItem} not found`);
          component = null;
          break;
      }



      // Check if the path already exists
      if (component && !addedPaths.has(routesData[key].parent)) {
        addedPaths.add(routesData[key].route);
        const routeConfig = {
          path: routesData[key].route,
          element: component,
          id: key,
        };

        if (hasLoader) {
          routeConfig.action = formAction;
          routeConfig.loader = ({ params, request }) => fetchForm(parent);
        }

        routes.push(routeConfig);
      }
    });

    return routes;
  })() : [];

  const routes = [];
  const addedPaths = new Set();

Object.entries(routesData).forEach(([key, value]) => {
    const component = <DynamicComponent key={key} type={value.type} />;

    // Assuming hasLoader is a condition you define or calculate based on your requirements


    // Check if the route's parent hasn't been added yet, and then add the route
    if (component && value.type && !addedPaths.has(value.route)) {
      addedPaths.add(value.route); // Use .route to ensure unique routes are tracked

      const routeConfig = {
        path: value.route,
        element: component,
        id: key,
      };


        // Assuming formAction and fetchForm are defined elsewhere
        routeConfig.action = formAction; // Define or import formAction according to your requirements
        if(value.type==='datatable'){
          routeConfig.loader =  ({ params, request }) => fetchDataObject({ params, request }, key, 'list');
        }
        else if(value.type==='form'){
          routeConfig.loader =  ({ params, request }) => fetchForm(key);
        }
        else if(value.type==='form-panel'){
          routeConfig.loader =  ({ params, request }) => fetchForm(key,'form-panel');
        }



      routes.push(routeConfig);
    }
    isLoading=false;
  });

//console.log(dynamicRoutes)
//console.log(routes)

  const router = createBrowserRouter([
    {
      path: '/',
      element: <Root />,
      errorElement:<ErrorPage/>,
      id:'token-loader',
      loader:rootLoader,
      action: formAction,
      children: [
        {
          index: true,
          element: <Home />,
          id: 'home',

          loader :homeLoader
        },
        {
          path:"agenda",
          loader: fetchCalendar,
          element:<Agenda/>
        },
        {
          path:'profile',



          children: [
            {
              index: true,
              element: <Profile/>,
              loader:profileLoader,

            },
            {
              path:'financials',
                 element: <Financials/>,
                 loader:financialsLoader,


               },
               {
                path:'integrations',
                   element: <Integrations/>


                 },

          ]

        },
        {
          path:'account',


          children: [
            {
              index: true,
              element: <Account/>,
              loader:accountLoader,


            },
            {
           path:'authorizations',
              element: <Authorizations/>


            },

            {
            path:'change-password',
            element: <ChangePassword/>,


          },


          ]


        },
        {
          path:'ask-product/:type',
          element:<LeadGeneric/>





    },

        ...dynamicRoutes,
        ...routes,







        {
          path: 'pages/:id',

          children: [
            {
            index:true,
            element:<ContentPage/>,
            loader: contentLoader,

            },



          ]


        },

      ],
    },
    {
      path: 'login',
      element: <Login />,
      id: 'login',
      loader: ({ params, request }) => fetchForm("login"),
      action: loginAction,
    },
    {
      path: 'register',
      element: <Register />,
      id: 'register',
      loader: ({ params, request }) => fetchForm("register"),
      action: registerAction,
    },
    {
      path: 'forgot-password',
      id: 'forgot-password',
      element: <ForgotPassword/>,
      loader: ({ params, request }) => fetchForm("forgot-password"),
      action: forgotPasswordAction,


    },
    {
      path: 'resend-email',

      element: <ResendEmailVerify/>




    },

    {
      path: 'reset-password/:token',
      id: 'reset-password',
      element: <ResetPassword/>,
      loader: ({ params, request }) => fetchForm("reset-password"),
      action:resetPasswordAction ,


    },
    {
      path: 'verify-email/:token',
      id: 'verify-email',
      element: <VerifyEmail/>,


    },
    {
      path: 'notification',

      element: <NotificationPage/>,



    },
  ]);

  if(isLoading)
  {
    return <div className='bg-white w-full flex justify-center  items-center min-h-screen'><img className='max-w-xs' src={`${process.env.PUBLIC_URL}/static/images/loading.gif`} alt="Logo"/></div>;
  }
  const queryClient = new QueryClient(); //tanstack client
  return (

    <React.Fragment>
<ErrorBoundary>
<SnackbarProvider>
      <TranslationProvider>
        <ActionDataProvider>
   <SnackBarContextProvider>
      <QueryClientProvider client={queryClient}>

      <AuthContextProvider>



        <ThemeProvider theme={theme}>
          <RouterProvider router={router} />
        </ThemeProvider>



      </AuthContextProvider>

      </QueryClientProvider>
      </SnackBarContextProvider>
      </ActionDataProvider>
      </TranslationProvider>
      </SnackbarProvider>
      </ErrorBoundary>
    </React.Fragment>

  );
};

export default App;
