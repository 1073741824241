import React, { useContext, useState, useCallback, useMemo } from 'react';
import MuiAppBar from '@mui/material/AppBar';

import Toolbar from '@mui/material/Toolbar';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Tooltip from '@mui/material/Tooltip';
import { Typography } from '@mui/material';
import PlatFormLogo from '../../UI/PlatFormLogo';
import { useMediaQuery, useTheme } from '@mui/material';
import { Link } from 'react-router-dom';
import AuthContext from '../../../store/context/auth-context__';
import { useSnackBar } from '../../../store/context/snackbar-context';
import Modal from '../../UI/Modal';


import Notifications from './Notifications';
import ProfileOptions from './ProfileOptions';
import LanguageSelector from '../../UI/LanguageSelector';
const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open, drawerwidth }) => ({
  position: 'fixed',
  top: 0,
  zIndex: !open ? theme.zIndex.drawer + 1 : theme.zIndex.appBar + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
  }),
  ...(open && {
    marginLeft: `${drawerwidth}px`,
    width: `calc(100% - ${drawerwidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.shortest,
    }),
  }),
}));

function TopNav(props) {
  const authCtx = useContext(AuthContext);


  const [anchorElUser, setAnchorElUser] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { notify } = useSnackBar();
//console.log(props.topNavItems);
  // Fetch translations for "Profile Options" and "Logout Success"


  const handleOpenUserMenu = useCallback((event) => setAnchorElUser(event.currentTarget), []);
  const handleCloseUserMenu = useCallback(() => setAnchorElUser(null), []);
  const handleModalOpen = useCallback(() => setOpenModal(true), []);
  const handleModalClose = useCallback(() => setOpenModal(false), []);

  const handleLogout = useCallback(async () => {
    authCtx.logoutHandler();
    setOpenModal(false);
    setAnchorElUser(null);
    notify(props.topNavItems.translation_items.logout.logout_success.label, 'info');
    // eslint-disable-next-line
  }, [authCtx, notify]);

  // Memoize props for Modal to prevent re-renders caused by new object references
  const modalProps = useMemo(() => ({
    title: 'exit',
    message: 'confirm-exit',
    open: openModal,
    onClose: handleModalClose,
    action: handleLogout,
  }), [openModal, handleModalClose, handleLogout]);

  return (
    <React.Fragment>

      <AppBar
        style={{ backgroundColor: props.backgroundColor }}
        position="fixed"
        open={props.open}
        drawerwidth={props.drawerWidth}
        elevation={0}
        className='h-auto top-0'
      >
        <Toolbar className='flex  flex-wrap justify-between'>
          <Box className='flex gap-2 sm:gap-6 '>
            <IconButton
              style={{ color: props.color }}
              edge="start"
              aria-label="open drawer"
              onClick={props.sideBarHandler}
              sx={{ ...(props.open && { display: 'none' }) }}
            >
              <MenuIcon />
            </IconButton>
            <Box className="flex gap-2 sm:gap-8">
              <Link className='flex items-center' to=".">
                <PlatFormLogo type={isMobile ? "entrance" : "small"} className={`max-h-8 ${props.open && 'hidden sm:block'} `} />
              </Link>
              <Box className="flex gap-8 sm:gap-16 items-center">
              <Typography variant="h6" sx={{ color: "primary.main" }}>
              Olá<strong> {authCtx.userData.first_name}</strong>!


              </Typography>
              </Box>
            { /* <Box className="flex gap-8 sm:gap-16 items-center">
                <Link to={"/profile/financials"}>
                <FinanceStatus  />
                </Link>
              </Box> */}
            </Box>
          </Box>
          <Box className="flex  items-center gap-2 sm:gap-6">
            <div className='flex items-center gap-0 sm:gap-4 '>
          <LanguageSelector color="dark" size="small" />
            <Notifications translations={props.topNavItems.translation_items.notifications} />
            </div>
            <Tooltip title={props.topNavItems.translation_items.top_menu.profile_options.label}> {/* Translated "Opções de Perfil" */}
              <IconButton onClick={handleOpenUserMenu} size='medium'>
                {authCtx.userData.photo && authCtx.userData.photo !== 'null' ? (
                  <img
                    src={`${process.env.REACT_APP_API_URL}${authCtx.userData.photo}`}
                    alt="User profile"
                    style={{ width: "1.8rem", height: "1.8rem", borderRadius: '100%' }} // Adjust size as needed
                  />
                ) : (
                  <span className={`p4l-person-line w-fit`}></span>
                )}
              </IconButton>
            </Tooltip>
            <ProfileOptions
              anchorEl={anchorElUser}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
              menuItems={props.topNavItems.items}
              translations={props.topNavItems.translation_items.top_menu}
              profiles={props.topNavItems.profiles}
              onOpen={handleModalOpen}
            />
          </Box>
        </Toolbar>
      </AppBar>
      {openModal && <Modal {...modalProps} />}
    </React.Fragment>
  );
}

export default React.memo(TopNav);
