import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Divider from '@mui/material/Divider';
import { updateProfile } from '../../../utils/cms';
import { IconButton} from '@mui/material';



const ProfileOptions = ({ profiles, anchorEl, open, onClose, menuItems, onOpen,translations }) => {

  const [selectedUser, setSelectedUser] = useState(localStorage.getItem("user") || null);

//console.log(translations);


  useEffect(() => {
    validateSelectedUser();
    /* eslint-disable */
  }, [profiles]);

  const validateSelectedUser = () => {
    const localUserPk = localStorage.getItem("user");

    const clientExists = profiles?.clients && profiles?.clients.id === localUserPk;
    const agentExists = profiles?.agents && profiles?.agents.id === localUserPk;

    if (!localUserPk) {
      const firstAvailableProfile = profiles?.clients?.id || profiles?.agents?.id;
      if (firstAvailableProfile) {
        localStorage.setItem("user", firstAvailableProfile);
        setSelectedUser(firstAvailableProfile);
      }
    }
    if (localUserPk && (clientExists || agentExists)) {
      setSelectedUser(localUserPk);
    }
  };

  //console.log(profiles)

  const handleUserSelect = async (pk, type) => {
    try {
      await updateProfile(pk, type);
      localStorage.setItem("user", pk);
      setSelectedUser(pk);
      window.location.reload(); // Consider using a less disruptive way to reflect changes
    } catch (error) {
      console.error("Failed to update profile:", error);
    }
  };

  useEffect(() => {
    if (profiles && profiles.selected_profile) {
      setSelectedUser(profiles.selected_profile.id);
      localStorage.setItem("user", profiles.selected_profile.id);
    }
  }, [profiles]);

  return (
    <Menu
      sx={{ mt: '45px' }}
      id="menu-appbar"
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      open={Boolean(anchorEl) && open}
      onClose={onClose}
    >
      <div className='w-fit flex flex-col gap-2'>
        {profiles?.clients?.length > 0 && (
          <>
            <Typography textAlign="center" style={{ fontWeight: 600, marginTop: 10 }}>
              {translations.clients.label}
            </Typography>
            {profiles.clients.map((client) => (
              <MenuItem className="flex justify-center gap-2 " key={client.id} onClick={() => handleUserSelect(client.id, "client")}>
                <IconButton size='small'>
                  <span className={`p4l-user w-6 h-full`}></span>
                </IconButton>
                <Typography textAlign="left">{client.full_name ? client.full_name : client.name}</Typography>
                {selectedUser === client.id ? (
                  <IconButton size='small'>
                    <span className={`p4l-check w-6 h-full`}></span>
                  </IconButton>
                ) : null}
              </MenuItem>
            ))}
          </>
        )}

        {profiles?.agents && (
          <>
            <Typography textAlign="center" style={{ fontWeight: 600, marginTop: 10 }}>
              {translations.agents.label}
            </Typography>
            <MenuItem className="flex justify-center gap-2 " onClick={() => handleUserSelect(profiles.agents.id, "agent")}>
              <IconButton size='small'>
                <span className={`p4l-user-tie w-6 h-full`}></span>
              </IconButton>
              <Typography textAlign="center">{profiles.agents.name}</Typography>
              {selectedUser === profiles.agents.id ? (
                <IconButton size='small'>
                  <span className={`p4l-check w-6 h-full`}></span>
                </IconButton>
              ) : null}
            </MenuItem>
          </>
        )}

        <Divider />
        {menuItems && menuItems.map((item, index) => (
          <React.Fragment key={index}>
            {item.divider && <Divider />}
            <Link to={item.destination}>
              <MenuItem onClick={onClose} className="flex justify-center gap-2 ">
                {item.icon && (
                  <IconButton size='small'>
                    <span className={`${item.icon} w-6 h-full`}></span>
                  </IconButton>
                )}
                <Typography textAlign="left">{item.name}</Typography>
              </MenuItem>
            </Link>
          </React.Fragment>
        ))}
      </div>
      <MenuItem onClick={onOpen} className="flex justify-center gap-2">
        <IconButton size='small'>
          <span className={`p4l-exit w-6 h-full`}></span>
        </IconButton>
        <Typography textAlign="left">{translations.exit.label}</Typography>
      </MenuItem>

    </Menu>
  );
};

export default ProfileOptions;
