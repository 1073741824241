import React, { useState } from 'react';
import { Box, Typography, FormGroup, FormControlLabel, Checkbox, FormControl } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { styled } from '@mui/material/styles';
import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom'; // For navigation
import Form from "../UI/Form"; // Reusable form component
import { getFinancialDocuments } from "../../utils/cms"; // API function

import { financeAtCheck } from '../../utils/cms';
import P4lIcon from '../UI/P4lIcon';
// Styled component for green check and red cross
const StatusIcon = styled(Box)(({ theme, status }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: status === 'GREEN' ? theme.palette.success.main : theme.palette.error.main,
  fontSize: 50,
  marginBottom: theme.spacing(2),
}));

// Document list with proper IDs


const FinancialStatusValid = ({ officialStatus,translatedLabels }) => {
  const { at_status, last_updated } = officialStatus;
  const [selectedDocs, setSelectedDocs] = useState([]);
  const [isLoading, setIsLoading] = useState(false); // Loading state for form submission



  const [isSelectAllChecked, setIsSelectAllChecked] = useState(false); // State for select all checkbox

  const navigate = useNavigate(); // To navigate to the notification page

  const documentsList = [
    { id: 'BP_mapa_resp', label: translatedLabels.financial_documents.mapa_responsabilidade.label },
    { id: 'PF_imi_cad_predial', label: translatedLabels.financial_documents.imi_caderneta_predial.label},
    { id: 'PF_imi_notas_cobranca', label: translatedLabels.financial_documents.imi_notas_de_cobranca.label},
    { id: 'PF_irs_modelo_3', label: translatedLabels.financial_documents.irs_modelo_3.label },
    { id: 'PF_irs_declaration', label: translatedLabels.financial_documents.irs_declaration.label },
    { id: 'PF_iuc_vehicles', label: translatedLabels.financial_documents.iuc_vehicles.label },
    { id: 'PF_data_vehicles', label: translatedLabels.financial_documents.data_vehicles.label},
    { id: 'PF_tax_domicile', label: translatedLabels.financial_documents.tax_domicile.label },
  ];
  // Format the date using date-fns
  const formattedDate = format(new Date(last_updated), 'yyyy-MM-dd HH:mm:ss');

  // Handle checkbox changes
  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    if (checked) {
      setSelectedDocs((prev) => [...prev, name]);
    } else {
      setSelectedDocs((prev) => prev.filter((doc) => doc !== name));
    }
  };

  // Handle select all checkbox
  const handleSelectAllChange = (event) => {
    const { checked } = event.target;
    setIsSelectAllChecked(checked);

    if (checked) {
      // Select all documents
      setSelectedDocs(documentsList.map((doc) => doc.id));
    } else {
      // Deselect all documents
      setSelectedDocs([]);
    }
  };

  // Handle form submit
  const handleSubmit = async (event) => {
    event.preventDefault();

    if (selectedDocs.length > 0) {
      setIsLoading(true); // Start loading

      try {
        // Call the API with the selected documents
        const response = await getFinancialDocuments(selectedDocs);

        if (response) {
          // If API call succeeds, navigate to the notification page
          navigate("/notification", {
            state: {
              messages: {
                title: "documents-requested",
                success: "documents-requested-info",
                instructions: "documents-requested-instructions",
              },
              buttons: [
                {
                  text: "back",
                  link: "/profile/financials",
                },
              ],
            },
          });
        }
      } catch (error) {
        //console.log(error)

        navigate("/notification", {
          state: {
            error:true,
            messages: {
              title: "documents-requested-error",
              success: "documents-requested-info-error",
              instructions: "documents-requested-instructions-error",
            },
            buttons: [
              {
                text: "back",
                link: "/profile/financials",
              },
            ],
          },
        });
      } finally {
        setIsLoading(false); // Stop loading
      }
    } else {
      alert("Please select at least one document.");
    }
  };

  const handleRefresh = async () => {
    try {
      const response = await financeAtCheck(); // Trigger financeAtCheck API call
      if (response) { navigate("/notification", {
        state: {
          messages: {
            title: "at-status-update",
            success: "at-status-update-success",
            instructions: "at-status-update-instructions",
          },
          buttons: [
            {
              text: "back",
              link: "/profile/financials",
            },
          ],
        },
      });
    }
    } catch (error) {
      console.error("Error triggering finance check:", error);
    }
  };



  return (
    <Form buttonText={translatedLabels.financial.get_documents.label} onSubmit={handleSubmit} isLoading={isLoading}>
      <Box display="flex" flexDirection="column" alignItems="center">
        {/* Display the green check or red cross based on at_status */}
        <StatusIcon status={at_status}>
          {at_status === "GREEN" ? (
            <CheckCircleIcon fontSize="inherit" />
          ) : (
            <CancelIcon fontSize="inherit" />
          )}
        </StatusIcon>

        {/* Display the status message */}
        <div className='flex flex-col gap-4 items-center'>
        <Typography variant="h5" fontWeight="bold">
          { at_status === 'GREEN' ? translatedLabels.financial.everything_good.label :translatedLabels.financial.issues_with_status.label }
        </Typography>
        { at_status==='RED' && <Typography variant="h6" >
          {translatedLabels.financial.at_red_instructions_message.label}
        </Typography>}

        {/* Display the last update date */}
        <div className='flex items-center gap-4'>
        <Typography variant="body1" color="textSecondary">
          {`${translatedLabels.financial.last_updated.label}: ${formattedDate}`}
        </Typography>
        <P4lIcon size="large" icon="p4l-refresh" className="text-4xl" onClick={handleRefresh} />
        </div>
        </div>
        {/* Select All Checkbox */}
        <Box mt={3} width="100%">
          <FormControl component="fieldset">
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isSelectAllChecked}
                    onChange={handleSelectAllChange}
                  />
                }
                label={translatedLabels.financial.select_all.label}
                sx={{marginBottom:"1rem"}}
              />
              {documentsList.map((doc) => (
                <FormControlLabel
                  key={doc.id}
                  control={
                    <Checkbox
                      name={doc.id}
                      checked={selectedDocs.includes(doc.id)}
                      onChange={handleCheckboxChange}
                    />
                  }
                  label={doc.label}
                  sx={{marginLeft:"2rem"}}
                />
              ))}
            </FormGroup>
          </FormControl>
        </Box>
      </Box>
    </Form>
  );
};

export default FinancialStatusValid;
