import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate, useActionData,json ,useNavigation} from "react-router-dom";
import Typography from "@mui/material/Typography";

import LinkMui from "@mui/material/Link";
import FormLayout from "../components/UI/DynamicForm/FormLayout";
import AuthForm from "../components/Auth/AuthForm";
import AuthContext from "../store/context/auth-context__";
import { useSnackBar } from "../store/context/snackbar-context";
import { useTranslationsContext } from "../store/context/translations-context";


function Login() {
  const data = useActionData();
  const authCtx = useContext(AuthContext);
  const { requestTranslation } = useTranslationsContext(); // Access TranslationContext
  const navigate = useNavigate();
  const { notify } = useSnackBar();
  const navigation = useNavigation();
  const [translations, setTranslations] = useState({
    noAccount: '',
    registerHere: ''
  });
  const isRouterLoading = navigation.state === 'loading';
  useEffect(() => {
    if (authCtx.isLoggedIn) {
      navigate('/');
    }
  }, [authCtx.isLoggedIn, navigate]);

  useEffect(() => {
    const fetchCsrfToken = async () => {
      const response = await fetch(process.env.REACT_APP_API_URL + 'appauth/get-csrf-token/', {
        method: 'GET',
        credentials: 'include',  // Ensure cookies are included in the request
      });
      const result = await response.json();
      localStorage.setItem('csrfToken', result.csrf_token);
    };

    fetchCsrfToken();
  }, []);

  useEffect(() => {
    const loadTranslations = async () => {
      const noAccount = await requestTranslation('login-no-account');
      const registerHere = await requestTranslation('login-register-here');
      setTranslations({ noAccount, registerHere });
    };

    loadTranslations();
  }, [requestTranslation]);

  useEffect(() => {
    if(!data) return;
    if (data && data.status===200) {
      navigate("/");
    }

// eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    if (data && data.detail) {
      notify(data.detail, 'error');
    }


  }, [data, notify]);

//console.log(data);
//console.log(authCtx.isLoggedIn);

  return (
    <React.Fragment>
      { isRouterLoading ? (<div className='bg-white w-full flex justify-center  items-center min-h-screen'><img className='max-w-xs' src={`${process.env.PUBLIC_URL}/static/images/loading.gif`} alt="Logo"/></div>) : !authCtx.isLoggedIn ? (  <AuthForm>
        <FormLayout colSpan={12} formType="router" className="flex flex-col items-center gap-8" />
        <Typography className="flex gap-2 text-white" variant="body2">
          {translations.noAccount}
          <Link to="/register">
            <LinkMui sx={{ color: 'primary.accent', textDecoration: 'underline' }}>
              {translations.registerHere}
            </LinkMui>
          </Link>
        </Typography>
      </AuthForm>) : null}

    </React.Fragment>
  );
}

export default Login;

export async function action({ request }) {
  const { REACT_APP_API_URL } = process.env;
  const searchParams = new URL(request.url).searchParams;
  const mode = searchParams.get('mode') || 'login';

  if (mode !== 'login' && mode !== 'signup') {
    throw json({ message: 'Unsupported mode.' }, { status: 422 });
  }

  const data = await request.formData();

  const authData = {
    password: data.get('password'),
    email: data.get('email'),
  };

  const response = await fetch(REACT_APP_API_URL + '_allauth/browser/v1/auth/login', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRFToken': localStorage.getItem("csrfToken"),
    },
    credentials: 'include',
    body: JSON.stringify(authData),
  });

  if (response.status === 422 || response.status === 401 || response.status === 400) {
    const errorData = await response.json();
    return { ok: false, status: response.status, data: errorData };
  }

  if (!response.ok) {
    throw json({ message: 'Could not authenticate user.' }, { status: 401 });
  }

  const responseJson=await response.json();
  return responseJson;

}
