import React, { useState, createContext, useEffect } from 'react';
import { getCSRFToken } from '../../utils/auth';
import useHttpRequest from '../../hooks/use-httpRequest';
import { redirect } from 'react-router-dom';
import { json } from 'react-router-dom';

const AuthContext = createContext({
  isLoggedIn: 'null',
  emailVerified:'null',
  email:'null',
  photo:'null',
  userData:'null',
  logoutHandler: () => {},
  signupHandler: () => {},
  refetch: () => {},
  isLoading: false,
fetchAccountDetails: () => {},


});



export const AuthContextProvider = (props) => {
  const [isLoggedIn, setIsLoggedIn] = useState('null');

  const [photo, setPhoto] = useState('null');
  const email = "null";
  const [userData, setUserData] = useState('null');
  const token = getCSRFToken();



  const { isLoading, sendRequest, updateState } = useHttpRequest();

  useEffect(() => {

    const initialize = async () => {
      if (token) {
         await validateSession(false);



      } else {
        setIsLoggedIn(false);
      }
    };

    initialize();
    // eslint-disable-next-line
  }, []);

  const validateSession = async (userInfo=true) => {


    try {
      const response = await checkLoginStatus();

      if (!response || !response.ok) {
        if (isLoggedIn===true) logoutHandler();

        setIsLoggedIn(false);

          return;
        }
        setIsLoggedIn(true);



       if (userInfo) await fetchAccountDetails();
    } catch (err) {
      //console.log("Error during token validation", err);


    }
  };

  const fetchAccountDetails = async () => {


    try {
      const accountDetailsResponse = await userInfoDetails();




        const accountDetailsResponseJson = await accountDetailsResponse.json();
        //console.log(accountDetailsResponseJson);

        setPhoto(accountDetailsResponseJson.photo)
        setUserData(accountDetailsResponseJson);
    } catch (err) {
      //console.log("Failed to fetch photo", err);


    }
  };

  const checkLoginStatus = async () => {


    return await fetch(`${process.env.REACT_APP_API_URL}_allauth/browser/v1/auth/session`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRFToken': token,
      },
      credentials:"include"
    });
  };



  const userInfoDetails = async () => {


    return await fetch(`${process.env.REACT_APP_API_URL}webapp/user_info`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRFToken': token,
      },
      credentials:"include"
    });
  };


  const signupHandler = async (
    formData
  ) => {
    try {

  const authData = {
    email: formData.get('email'),
    password: formData.get('password'),
    "confirm-password": formData.get('confirm-password'),
    username: formData.get('username'),
    first_name: formData.get('first_name'),
    last_name: formData.get('last_name'),
  };
  if(authData.password !== authData["confirm-password"]){

    return { ok:false, "confirm-password": ['Passwords dont match'] };
   }
      const response = await sendRequest(
        `${process.env.REACT_APP_API_URL}/myuser/create`,
        'POST',
        {
         ...authData
        }
      );


  if (response.status === 400) {
    const errorData = await response.json();
    //console.log(errorData)
    return { ok: false, status: response.status, data: errorData };
  }

  if (!response.ok) {
    throw json({ message: 'Could not authenticate user.' }, { status: 500 });
  }
      return redirect('/login')

    } catch (err) {
      updateState({
        error: err.message,
      });
    }
  };



  const logoutHandler = async () => {

    const csrfToken = getCSRFToken();
try{
    await fetch(`${process.env.REACT_APP_API_URL}_allauth/browser/v1/auth/session`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRFToken': csrfToken,
      },
      credentials: 'include', // Ensure cookies are sent with the request
    });

    setIsLoggedIn(false);
    //deleteAllCookies();
    localStorage.removeItem('token');

    localStorage.removeItem('refresh');

    localStorage.removeItem('user');

return window.location.href = "/";

  }
  catch(error){
    setIsLoggedIn(false);
    //deleteAllCookies();
    localStorage.removeItem('token');

    localStorage.removeItem('refresh');

    localStorage.removeItem('user');

  }
  };

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn: isLoggedIn,

        photo:photo,
        email:email,
        userData:userData,
        logoutHandler: logoutHandler,
        signupHandler: signupHandler,
        refetch: validateSession,
        isLoading: isLoading,
        fetchAccountDetails:fetchAccountDetails,


      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContext;

